import React from 'react';
import './App.css';
import useLocalStorage from './hooks/useLocalStorage';


const stoppuhrStyles = {
	padding: '0',
	backgroundColor: null,
	width: '500px',
	height: '500px',
	// flex and position in middle
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}
const timeStyles = {
	backgroundImage: 'url(/stoppuhr-display.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	padding: '30px',
	width: '160px',
	textAlign: 'center',
}

const images = [
	'/01.png',
	'/02.png',
	'/03.png'
];

function App() {
	// create a simple app that displays a timer which starts at 0 and uses milliseconds
	// the time should be displayed in the format 0.000 (seconds.milliseconds)
	// the timer starts if the user clicks on the OUTER container
	// the time should be displayed in an inner container
	// when the user clicks again the timer stops and displays the exact time
	// when the timer is stopped, clicking again leads to a reset of the timer
	const [time, setTime] = React.useState(0);
	const [startTime, setStartTime] = React.useState(null);
	const [isRunning, setIsRunning] = React.useState(false);
	const [isStopped, setIsStopped] = React.useState(false);
	const [backgroundImage, setBackgroundImage] = React.useState(images[0]);
	const [code, setCode] = React.useState(null); // { dateTime: Date.now(), time: 0

	const [scoreList, setScoreList] = useLocalStorage('local-bestscores', []); // { dateTime: Date.now(), time: 0}

	const [showMore, setShowMore] = React.useState('play');


	const startTimer = () => {
		setStartTime(Date.now());
		setIsRunning(true);
		setIsStopped(false);
		setCode(null);

		// change background image for 313ms and then change it back
		setBackgroundImage(images[1]);
		setTimeout(() => {
			setBackgroundImage(images[0]);
		}, 313);
	}

	const stopTimer = () => {
		let generatedCode = null;
		let msDiv10 = Math.floor(time/10);


		if(msDiv10 === 1000){
			generatedCode = generateRandomId();
		}

		let unorderedList = [...scoreList, { 
			dateTime: Date.now(), 
			// format d.m.yyyy hh:mm:ss
			dateString: new Date().toLocaleString('de-DE'),
			time: msDiv10, 
			code: generatedCode 
		}];
		
		// sort the list where the time which is closest to 10000 is on top
		unorderedList.sort((a, b) => {
			return Math.abs(a.time - 1000) - Math.abs(b.time - 1000);
		});


		
		setCode(generatedCode);
		setScoreList(unorderedList);
		setIsRunning(false);
		setIsStopped(true);
	}

	const resetTimer = () => {
		setStartTime(null);
		setTime(0);
		setIsStopped(false);
		setCode(null);
	}


	React.useEffect(() => {
		let interval = null;
		if (isRunning) {
			interval = setInterval(() => {
				// check now vs startTime and set time
				const now = Date.now();
				const diff = now - startTime;
				setTime(diff);
			}, 7);
		} else if (!isRunning && time !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}
	, [isRunning, time]);

	function handleClick(e) {
		// only trigger if the user clicks on the outer container
		if (e.target.className === 'stoppuhr-outer' || !isStopped )
			isStopped ? resetTimer() : isRunning ? stopTimer() : startTimer()
	}

	function generateRandomId() {
		const alphabet = 'abcdefghijklmnopqrstuvwxyz';
		const randomNumberForAlphabet = Math.floor(Math.random() * alphabet.length);

		return 'STOP10-' + alphabet[randomNumberForAlphabet] + '-' + Math.random().toString(36).substr(7, 9) + '.' + (randomNumberForAlphabet + 1);
	}

	function setBackgroundImageByIndex(index) {
		setBackgroundImage(images[index]);
	}

	return (
		<div className="stoppuhr-js">
			{showMore === 'scoreboard' ? <div className='scorelist' style={stoppuhrStyles}>
				<table>
					<thead>
						<tr>
							<th>Gespielt am</th>
							<th>Zeit</th>
							<th>Gewinn</th>
						</tr>
					</thead>
					<tbody>
						{scoreList.map((score, index) => {
							if(index>=10)
								return null;
							return <tr key={index}>
								<td>{score.dateString}</td>
								<td>{(score.time / 100).toFixed(3)}</td>
								<td>{score.code ? 'Ja' : 'Nein'}</td>
							</tr>
						})}
					</tbody>

				</table>

			</div> : null}

			{showMore === 'code' ? <div className='code' style={stoppuhrStyles}>
				<div>
					<h1>Erspielte Codes</h1>

					<ul>
				{scoreList.length > 0 && scoreList.map((score, index) => {
					if (score.code) {
						return <li key={index}>
							<code>{score.code}</code>
						</li>
					}
					return null;
				}
				)}
				</ul>
			</div></div> : null}

			
			{showMore === 'play' ? <div 
				onMouseEnter={() => { if(!isRunning) setBackgroundImageByIndex(2) }} onMouseLeave={() => { setBackgroundImageByIndex(0) }}
				className="stoppuhr-outer" 
				onClick={handleClick} 
				style={{...stoppuhrStyles, backgroundImage: `url(${backgroundImage})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'contain'}}>
				{!isStopped ? <div className='running-circle'>
					<div className="inner-container" style={timeStyles}>
						{(time/1000).toFixed(2)}
					</div>
				</div> : null}
				{isStopped && Math.floor(time/10) !== 1000 ? <div className='stoppuhr-finished'>
						<div style={{textAlign:'center',color:'#fff'}}>
							<h3>Schade ... das waren <strong>{Math.abs(10000-time)}</strong> ms zu {(10000-time) > 0 ? 'schnell' : 'langsam'}</h3>
							<div className="inner-container" style={timeStyles}>
								{(Math.floor(time/10)/100).toFixed(2)}
							</div>
							<p>
								Das waren <strong>{Math.abs(10000-time)}</strong> ms zu {(10000-time) > 0 ? 'schnell' : 'langsam'}.
							</p>
							<p>
								Versuche es nochmal und gewinne 20% Rabatt auf deine nächste Bestellung.
							</p>
							<button onClick={resetTimer}>Nochmal</button>
						</div>
					</div> : null}
				{isStopped && Math.floor(time/10) === 1000 ? <div className='stoppuhr-finished'>
					<div style={{color:'#fff'}}>
						<h1 className='text-center' style={{margin:'0 0 10px 0'}}>Glückwunsch, auf die Millisekunde genau!</h1>
						
					<div className="inner-container" style={timeStyles}>
						{(Math.floor(time/10)/100).toFixed(2)}
					</div>
					<div className='text-center'><strong>20% Rabatt</strong> auf deine Nächste Bestellung</div>
					<code>{code}</code>
					<div className='small text-center'>Fotografiere dein Ergebnis und gib den Code bei deiner nächsten Bestellung an.</div>
					<div className='text-center'>
					<a href="https://www.pizza-roller.org/speisekarte.php" target="_blank">Ich habe Hunger!</a>
					<button onClick={resetTimer}>Yummy! Das kann ich nochmal!</button>
					</div>
					</div>
				</div> : null}
			</div> : null}

			<div className='actions'>
				<button onClick={() => { setShowMore(showMore === 'scoreboard' ? 'play' : 'scoreboard')}}>Top 10</button>
				<button onClick={() => { setShowMore(showMore === 'code' ? 'play' : 'code')}}>Erspielte Codes</button>
			</div>



			
		</div>
	);

}

export default App;
